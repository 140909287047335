// Vendor libs
@import 'vendor/normalize/lib';
// Normalize.css
@import 'vendor/magento-ui/lib';
// Magento UI
// Theme variables
@import 'variables';

// Magento/blank
.page-print {
    .logo {
        float: none;
        display: block;
        text-align: left;
    }
}

@media print {

    //Print order styling
    .order-items {
        th {
            padding-bottom: 0;
            padding-top: 0;
            padding-left: 0;
            font-weight: normal;
        }
    }
    
    .block-order-details-view {
        .block-content {
            display: flex;
            justify-content: space-between;
        }
        
        .box {
            strong {
                span {
                    font-size: 14px !important;
                }
            }
        }
    }
    
    .order-details-items {
        margin-bottom: 10px;
        
        tbody td {
            padding: 5px 0;
        }
    }
    
    .block-order-details-view {
        .block-title {
            padding-bottom: 0 !important;
            margin-bottom: 5px !important;
        }
        
        strong {
            font-size: 18px !important;
        }
    }
    
    .page-title {
        font-size: 18px;
        margin-bottom: 0;
    }
    
    .account .table-wrapper {
        &:last-child {
            border-bottom: 0;
        }
    }
    
    #item-order-row {
        td {
            padding-left: 0;
        }
        
        span {
            padding-left: 0;
        }
    }
    
    .order-details-items tbody td {
        padding-left: 0;
    }
    
    .order-details-items {
        span.price {
            font-weight: normal;
        }
        
        .price-excluding-tax {
            font-size: unset;
        }
    }
    
    .page-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .footer-top-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        
        h3 {
            font-size: 16px;
            
        }
        
        span {
            font-size: 14px;
        }
        
        p {
            font-size: 12px;
        }
    }
    
    .order-status {
        float: right;
    }
    
    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        -webkit-filter: none !important; // Use in 41 Chrome
        filter: none !important;
    }
    
    // Black prints faster:h5bp.com/s
    a,
    a:visited {
        text-decoration: underline !important;
    }
    
    // Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        border: 1px solid $color-gray60;
        page-break-inside: avoid;
    }
    
    thead {
        display: table-header-group;
    }
    
    .table-wrapper table {
        width: 100%;
        
        td {
            width: auto;
        }
        
        table-layout: fixed;
        
        & > tfoot > tr:first-child {
            border-top: 1px solid $color-gray60;
        }
    }
    
    .box,
    tr,
    img {
        page-break-inside: avoid;
    }
    
    img {
        max-width: 100% !important;
    }
    
    @page {
        margin: 1cm;
    }
    
    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    
    .block-content {
        page-break-before: avoid;
    }
    
    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }
    
    .nav-toggle {
        display: none !important;
    }
    
    .sidebar,
    .nav-sections,
    .header.content > *[class],
    .panel.wrapper > *[class],
    .footer.content > *[class] {
        display: none;
    }
    
    .logo,
    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }
    
    .order-details-items {
        .order-items {
            .order-gift-message {
                &:not(.expanded-content) {
                    visibility: visible;
                    height: auto;
                }
            }
        }
    }
    
    .column.main {
        width: 100% !important;
        float: none !important;
    }
    
    .breadcrumbs {
        margin: 0 auto;
        text-align: right;
    }
    
    .footer.content {
        padding: 0;
    }
}

//.order-items {
//    th {
//        padding-bottom: 0;
//        padding-top: 0;
//        padding-left: 0;
//        font-weight: normal;
//    }
//
//}
//
//#item-order-row {
//    td {
//        padding-left: 0;
//    }
//
//    span {
//        padding-left: 0;
//        color: blue !important;
//
//    }
//}
//
//.order-details-items {
//    span.price {
//        font-weight: normal;
//    }
//
//    .price-excluding-tax {
//        font-size: unset;
//    }
//}
